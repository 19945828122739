<template>
    <div class="QuestionPapers">
        <!-- 加载中 -->
        <Spin fix v-if="QuestionPapers_Loading">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
        <!-- 测试试卷&&练习试卷 -->
        <TestPaper :PapersData="PapersData" 
        :courseExerciseId="$route.query.id"
        @handExercise="handExercise" 
        v-if="(execType == 1 || execType == 5) &&state <10"/>

        <!-- 模考试卷 -->
        <MockExam :PapersData="PapersData" 
        :courseExerciseId="$route.query.id"
        @handMock="handMock"
        v-if="execType == 10 &&state <10" @refresh="refresh"/>

        <!-- 答案解析 -->
        <AnswerParse v-if="(state ==10 || state>10) && (execType == 1 || execType == 5)" :userPaperId="userPaperId"/>

        <!-- 模考答案解析 -->
        <MockAnswer v-if="(state ==10 || state>10) && execType == 10" :userPaperId="userPaperId" :isAnalysisReport="isAnalysisReport" @PackUpReport="PackUpReport"/>
        
        <!-- 生成模考分析报告 -->
        <div class="create" v-if="iscreate">
            <p>正在生成模考分析报告，请等候</p>
            <img src="@/assets/create.gif" alt="" class="createimg">
            <img src="@/assets/light.png" alt="" class="light">
        </div>
    </div>
</template>

<script>
import { GetExerciseDetail } from "@/api/QuestionPapers"
import TestPaper from "@/views/QuestionPapers/TestPaper"
import AnswerParse from "@/views/QuestionPapers/AnswerParse"
import MockExam from "@/views/QuestionPapers/MockExam"
import MockAnswer from "@/views/QuestionPapers/MockAnswer"
export default {
    name: 'demo',
    props: {},
    components: {
        TestPaper,
        AnswerParse,
        MockExam,
        MockAnswer
    },
    data () {
       return {
            QuestionPapers_Loading: false,
            PapersData: {},
             // 试卷类型
            execType: 0,
            state: 0,
            iscreate: false,
            isAnalysisReport: false,
            userPaperId: '',
            currentUrl: ""
       }
    },
    created () {
        if(this.$route.query.userPaperId&&!this.$route.query.state){
            // 刷题报告过来查看模考分析
            this.userPaperId = this.$route.query.userPaperId
            this.state = 10
            this.isAnalysisReport = true
            this.execType = 10
        }else{
            this.state = this.$route.query.state
            // this.userPaperId = this.$route.query.userPaperId
            this.GetExerciseDetail()
        }
    },
    mounted () {
        // 记录当前页面url
	    this.currentUrl = window.location.href
    },
    methods: {
        // 获取试卷详情
        GetExerciseDetail(){
            this.QuestionPapers_Loading = true
            let ExerciseData = {
                paperId: this.$route.query.paperId,
                belongId: this.$route.query.belongId,
                belongType: this.$route.query.belongType
            }
            GetExerciseDetail(ExerciseData).then((res)=>{
                // console.log(res)
                this.QuestionPapers_Loading = false
                this.PapersData = res.data
                this.execType = res.data.execType
                this.userPaperId = res.data.userPaperId
                // this.state = this.PapersData.state
            })
        },
        
        // 练习试卷交卷
        handExercise(){
            this.state = 10
        },

        // 模考试卷交卷
        handMock(){
            this.iscreate = true
            setTimeout(() => {
                this.isAnalysisReport = true
                this.iscreate = false
                this.state = 10
            }, 3000);
        },

        // 开始考试刷新页面
        refresh(){
            this.execType = 0
            this.GetExerciseDetail()
        },
        // 收起报告
        PackUpReport(){
            this.isAnalysisReport = false
        },
    },
    beforeRouteLeave(to, from, next) {
        // 模考试卷离开页面提示
        if(this.execType == 10 &&this.state == 5 && localStorage.token){
            this.$Modal.confirm({
                title: '温馨提示',
                content: '当前模考试卷将在倒计时结束后自动交卷，中途退出不停止计时,确认退出吗？',
                onOk: () => {
                    next()
                },
                onCancel: () => {
                    next(false);
                }
            })
        }else{
            next()
        }
    },
}
</script> 

<style lang='less' scoped>
    .QuestionPapers{
        min-height: 100vh;
        position: relative;
        .create{
            width: 100%;
            height: 100%;
            background-color: #f8f8f8;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999999999;
            p{
                margin-top: 98px;
                font-size: 24px;
                font-weight: 400;
                color: #333333;
            }
            .createimg{
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .light{
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>
