<template>
    <div class="TestPaper" @click="ClickAll">
        <Head />
        <div class="middle">
            <div class="content">
                <!-- 试卷列表 -->
                <div class="SubjectList">
                    <div class="List_item" :class="'quesshow' + index" v-for="(item,index) in PapersData.questionList" :key="item.questionIndex">
                        <Paper :SubjectList="item" :TestDate="TestDate" :questionUseTime="questionUseTime" @ClearQuestionTime="ClearQuestionTime" @RefreshAnswer="RefreshAnswer"/>
                    </div>

                    <div class="last">已经是最后一题啦，做完就点击左侧交卷吧</div>
                </div>
                <!-- 模考未开始遮罩 -->
                <div class="mock_shade" v-if="PapersData.state == 1">
                    <p>点击开始考试后，即可查看题目</p>
                </div>
                <!-- 开始考试 -->
                <div class="StartCard" v-if="PapersData.state == 1">
                    <div class="StartCard_content">
                        <div class="StartCard_top">
                            <div class="StartCard_tit">{{PapersData.name}}</div>
                            <div class="StartCard_limit">
                                <div class="StartCard_limit_item">● 考试题目 : <span>{{PapersData.questionNum}}题</span></div>
                                <div class="StartCard_limit_item">● 考试时间 : <span>{{PapersData.limitTime}}分钟</span></div>
                            </div>
                            <div class="StartCard_explain">
                                <div class="StartCard_explain_tit">考试说明</div>
                                <p>1、本试卷每位学员只能做一次 </p>
                                <p>2、考试开始计时后，中途退出，计时不停止，</p>
                                <p class="line_feed">倒计时结束后将自动交卷 </p>
                                <p>3、答卷完成后，将会有详细的分析报告及学习</p>
                                <p class="line_feed">指导意见，请认真对待！</p>
                                <p>4、请准备好草稿纸，找一个无人打扰的环境，</p>
                                <p class="line_feed">拿出你最好的状态，点击下方按钮开始考试。</p>
                            </div>
                        </div>
                        <div class="StartCard_bottom">
                            <Button type="primary" @click="Startexam" class="Startexam">开始考试</Button>
                            <Button @click="gohome" class="gohome">下次再考</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="AnswerSheet" v-if="PapersData.state == 5" :class="{glide: !this.isAnswerSheet}">
            <div class="AnswerSheet_content">
                <div class="cardList" ref="scrollview" @mousewheel="scrollChange">
                    <div class="cardList_item" v-for="(item,index) in PapersData.cardList" :key="item.moduleId">
                        <div class="cardList_item_moduleName">{{item.moduleName}}</div>
                        <div class="questionList">
                            <div class="questionList_item" v-for="(item2,index2) in item.questionList" :class="{option_item_checked:item2.hadAnswer == true}" @click="ClickQuestionIndex(item2.questionIndex-1)">
                                {{item2.questionIndex}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pack_up" @click="ClickAnswerSheet">答题卡
                    <Icon type="ios-arrow-down"  v-show="this.isAnswerSheet"/>
                    <Icon type="ios-arrow-up" v-show="!this.isAnswerSheet"/>
                </div>
            </div>
        </div>
        <!-- 右下角模块 -->
        <div class="submit_but" v-if="PapersData.state == 5">
            <div class="submit_top">{{PapersData.name}}</div>
            <div class="AnswerSheet_time">
                <img src="@/assets/countdown.png" alt="">
                <CountDown :target="targetTime" @on-end="handleEnd" v-font="24" />
            </div>
            <Button type="primary" @click="ClickSubmit" style="margin-top: 10px;width: 108px;">交卷</Button>
        </div>
        <!-- 交卷model -->
        <Modal
            v-model="examinationmodal"
            footer-hide width="291" :closable="false" :styles="{top: '30%'}">
            <div class="exammodal">
                <p>检查好了吗？确认交卷吗？</p>
                <Checkbox v-model="single" class="box">老师点评后通过短信通知我</Checkbox>
                <div class="exammodal_btn">
                    <Button shape="circle" size="small" class="exammodal_btn_item" @click="examinationmodal = false">我再看看</Button>
                    <Button type="primary" shape="circle" size="small" class="exammodal_btn_item" @click="affirmSubmit">确认交卷</Button>
                </div>
            </div>
        </Modal>

        <!-- 倒计时结束model -->
        <Modal
            v-model="countdownmodal"
            footer-hide width="291" :closable="false" :mask-closable="false" :styles="{top: '30%'}">
            <div class="exammodal">
                <p>考试时间到，请确认交卷</p>
                <Checkbox v-model="single" class="box">老师点评后通过短信通知我</Checkbox>
                <div class="exammodal_btn">
                    <Button type="primary" shape="circle" size="small" class="exammodal_btn_item" @click="affirmSubmit">确认交卷</Button>
                </div>
            </div>
        </Modal>

        <!-- 无操作弹窗 -->
        <div class="Nooperation_pop" v-if="isNooperation" @click="operationMock">
            <div class="Nooperation_mock"></div>
            <img src="@/assets/Nooperation.png" alt="" class="Nooperation_img">
        </div>
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
import Paper from "@/components/Paper"
import { GetExerciseDetail, ExercisePaperAnswer, PaperExamStart} from "@/api/QuestionPapers"
export default {
    name: 'TestPaper',
    props: {
        PapersData: Object,
        courseExerciseId: String
    },
    components: {
        Head,
        Paper
    },
    data () {
       return {
            isAnswerSheet: true,
            showIndex: '',
            // 整卷累计总耗时
            timer: '',
            // 整卷累计总耗时-秒
            TestDate: 0,
            // 本题累计总耗时-秒
            questionUseTime: 0,
            // 倒计时
            targetTime: new Date().getTime() + this.PapersData.examLessTime*1000,
            // 单题计时
            questionTimer: '',
            // 无操作计时
            NooperationTimer: '',
            // 交卷
            examinationmodal: false,
            single: false,
            countdownmodal: false,
            leaveModal: false,
            currentUrl: "",
            // 无操作计时 - 秒
            Nooperation: 0,
            isglide: false,
            isNooperation: false
       }
    },
    created () {
        if(this.PapersData.state == 1){
            document.body.style.overflow = "hidden"
        }
    },
    mounted () {
        // 考试开始
        if(this.PapersData.state == 5){
            // 考试开始计时
            this.TestDate = this.PapersData.useTime -1
            let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
            this.timer = setInterval(() => {
                _this.TestDate = _this.TestDate+1
            }, 1000)
            // 单题计时
            this.questionTimer = setInterval(() => {
                _this.questionUseTime = _this.questionUseTime+1
            }, 1000)
            // 无操作计时
            this.NooperationTimer = setInterval(() => {
                _this.Nooperation = _this.Nooperation+1
                // 两分钟无操作打开遮罩，暂停计时
                if(_this.Nooperation == 120){
                    // 禁止滚动
                    document.body.style.overflow = "hidden"
                    this.isNooperation = true
                    clearInterval(this.questionTimer);
                    clearInterval(this.NooperationTimer);
                }
            }, 1000)
            // 监听页面滚动事件
            window.addEventListener("scroll", this.handleScroll);
            // 答题卡模块滚动事件
            const scrollview = this.$refs['scrollview'];
            scrollview.addEventListener('scroll', this.scrollChange, true);
            // 监听页面关闭和刷新
            window.onbeforeunload = function (e) {
                if (_this.$route.name == "QuestionPapers") {
                    _this.$Message.warning({
                        content: '当前模考试卷将在倒计时结束后自动交卷，中途退出不停止计时，请尽量耐心做完哦',
                        duration: 5
                    });
                    // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
                    return '';
                } else {
                    window.onbeforeunload = null
                }
            };
        }
    },
    beforeDestroy() {
        if (this.timer) {
          clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
        if(this.questionTimer){
            clearInterval(this.questionTimer); // 在Vue实例销毁前，清除我们的定时器
        }
        if(this.NooperationTimer){
            clearInterval(this.NooperationTimer); // 在Vue实例销毁前，清除我们的定时器
        }
        if(this.PapersData.state == 5){
            window.removeEventListener('scroll', this.handleScroll);//移除监听页面滚动事件
            // 移除答题卡模块滚动事件
            const scrollview = this.$refs['scrollview'];
            scrollview.removeEventListener('scroll', this.scrollChange, true);
        }
    },
    methods: {

        // 展开、收起答题卡
        ClickAnswerSheet(){
            this.isAnswerSheet = !this.isAnswerSheet
        },

        // 点击答题卡跳到对应的位置
        ClickQuestionIndex(num){
            var el = document.getElementsByClassName(`quesshow${num}`)[0]
	      if (this.showIndex === num) {
	        this.showIndex = null
	      } else {
	        this.showIndex = num
	      }
	      this.$nextTick(function () {
	        window.scrollTo({'behavior': 'smooth', 'top': el.offsetTop})
	      })
        },

        // 点击交卷
        ClickSubmit(){
            this.examinationmodal = true
        },

        // 确认交卷
        affirmSubmit(){
            let param = {
                userPaperId : this.PapersData.userPaperId,
                useTime : this.TestDate,
                // port 1小程序 2PC
                port: 2,
                msgNotice: this.single ? 1 : 0
            }
            ExercisePaperAnswer(param).then((res=>{
                if(res.ret == 1){
                    this.examinationmodal = false
                    this.$emit("handMock");
                }else{
                    console.log(res.msg)
                }
            }))
        },

        // 秒转时分秒
        formatSeconds(value) {
            let result = parseInt(value)
            let h = Math.floor(result / 3600) < 10 ? '0' +  Math.floor(result / 3600) : Math.floor(result / 3600);
            let m = Math.floor((result / 60 % 60)) < 10 ?  '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
            let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
            let res = '';
            res += `${h}`;
            res += `:${m}`;
            res += `:${s}`;
            return res;
        },

        // 清除本题做题时间
        ClearQuestionTime(){
            this.questionUseTime = 0
        },

        // 刷新答题卡
        RefreshAnswer(){
            let ExerciseData = {
                paperId: this.$route.query.paperId,
                belongId: this.$route.query.belongId,
                belongType: this.$route.query.belongType
            }
            GetExerciseDetail(ExerciseData).then((res)=>{
                this.PapersData.cardList = res.data.cardList
            })
        },

        // 倒计时结束
        handleEnd () {
            this.countdownmodal = true
        },

        // 返回首页
        gohome(){
            this.$router.push({path: '/'})
        },
        
        // 开始考试
        Startexam(){
            PaperExamStart({userPaperId:this.PapersData.userPaperId}).then((res)=>{
                if(res.ret == 1){
                    // 刷新页面
                    this.$emit("refresh");
                }
            })
        },

        // 全局点击事件
        ClickAll(){
            // 有操作清除无操作时间
            this.Nooperation = 0
        },

        // 监听页面滚动事件
        handleScroll(){
            // 有操作清除无操作时间
            this.Nooperation = 0
        },

        // 答题卡模块滚动事件
        scrollChange (e) {
            // 有操作清除无操作时间
            this.Nooperation = 0
        },

        // 点击未操作遮罩
        operationMock(){
            let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
            this.isNooperation = false
            // 恢复滚动
            document.body.style.overflow = "visible"
            // 单题计时
            this.questionTimer = setInterval(() => {
                _this.questionUseTime = _this.questionUseTime+1
            }, 1000)
            // 无操作计时
            this.NooperationTimer = setInterval(() => {
                _this.Nooperation = _this.Nooperation+1
                // 两分钟无操作打开遮罩，暂停计时
                if(_this.Nooperation == 120){
                    // 禁止滚动
                    document.body.style.overflow = "hidden"
                    this.isNooperation = true
                    clearInterval(this.questionTimer);
                    clearInterval(this.NooperationTimer);
                }
            }, 1000)
        }
    },
}
</script> 

<style lang='less' scoped>
    .TestPaper{
        min-height: 100vh;
        background-color: #f8f8f8;
        position: relative;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1030px;
            .content{
                width: 1030px;
                min-height: 100vh;
                text-align: left;
                background: #FFFFFF;
                border-radius: 12px;
                margin-top: 80px;
                padding: 43px 30px 77px;
                position: relative;
                .SubjectList{
                    .last{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
                .mock_shade{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #FFFFFF;
                    opacity: 0.9;
                    border-radius: 12px;
                    z-index: 99;
                    text-align: center;
                    p{
                        font-size: 24px;
                        font-weight: 400;
                        color: #000000;
                        margin: 367px auto;
                    }
                }
                .StartCard{
                    position: fixed;
                    top: 80px;
                    right: 0;
                    width: 341px;
                    height: 80%;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 3px #EEEEEE;
                    border-radius: 12px 0px 0px 12px;
                    z-index: 99;
                    padding: 40px 20px 30px;
                    .StartCard_content{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .StartCard_top{
                            .StartCard_tit{
                                font-size: 20px;
                                font-weight: 400;
                                color: #000000;
                                text-align: center;
                            }
                            .StartCard_limit{
                                margin-top: 30px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #666666;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding-bottom: 30px;
                                border-bottom: 1px solid #eee;
                                span{
                                    color: #D13F3F;
                                    font-weight: bold;
                                }
                            }
                            .StartCard_explain{
                                margin-top: 34px;
                                .StartCard_explain_tit{
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #000000;
                                    margin-bottom: 14px;
                                }
                                p{
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #666666;
                                    margin-bottom: 5px;
                                }
                                .line_feed{
                                    margin-left: 23px;
                                }
                            }
                        }
                        .StartCard_bottom{
                            display: flex;
                            justify-content: space-between;
                            .Startexam{
                                width: 120px;
                            }
                            .gohome{
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }
        .AnswerSheet{
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1030px;
            max-height: 280px;
            background: #FFFFFF;
            box-shadow: 0px -12px 20px 0px #EEEEEE;
            border: 1px solid #D6D6D6;
            border-bottom: 0;
            z-index: 99;
            transition: all 0.2s ease-in-out;
            .AnswerSheet_content{
                width: 100%;
                height: 100%;
                position: relative;
                .cardList{
                    max-height: 280px;
                    text-align: left;
                    padding: 30px;
                    overflow: hidden;
                    overflow-y: auto;
                    .cardList_item{
                        padding-top: 10px;
                    }
                    .cardList_item:first-child{
                        padding-top: 0;
                    }
                    .cardList_item_moduleName{
                        font-size: 18px;
                        font-weight: bold;
                        color: #000000;
                        margin-bottom: 12px;
                    }
                    .questionList{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .questionList_item{
                            width: 36px;
                            height: 36px;
                            background: #FFFFFF;
                            border: 1px solid #D6D6D6;
                            border-radius: 8px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #666666;
                            text-align: center;
                            line-height: 36px;
                            margin-bottom: 10px;
                            margin-right: 10px;
                            cursor: pointer;
                        }
                        .questionList_item:hover{
                            background: #FB7878;
                            color: #FFFFFF;
                            border: none;
                        }
                        .questionList_item:active{
                            background: #FF9090;
                            color: #FFFFFF;
                            border: none;
                        }
                        .option_item_checked{
                            background-color: #FFEBEB;
                            color: #E30000;
                            border: 1px solid #FB7878;
                        }
                        .questionList_item:nth-child(21n){
                            margin-right: 0px;
                        }
                    }
                }
                .cardList::-webkit-scrollbar {
                  width: 5px;
                  height: 15px;
                }
                .cardList::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  background-color: #c1c1c1;
                }
                .cardList::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  background: #ededed;
                  border-radius: 10px;
                }
                .pack_up{
                    position: absolute;
                    top: -32px;
                    left: -1px;
                    width: 101px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    background: #FFFFFF;
                    border: 1px solid #D6D6D6;
                    box-shadow: 0px -12px 20px 0px #EEEEEE;
                    border-radius: 8px 8px 0px 0px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    cursor: pointer;
                }
                .pack_up:hover{
                    color: #FB7878;
                }
            }
        }
        .glide{
            top: calc(100vh - 3%);
        }
        .submit_but{
            position: fixed;
            width: 238px;
            background: #FFFFFF;
            box-shadow: 0px 0px 5px 0px #EEEEEE;
            border-radius: 8px;
            bottom: 20px;
            left: 50%;
            transform: translateX(221%);
            padding: 15px 10px 20px;
            .submit_top{
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                border-bottom: 1px solid #DBDBDB;
                padding-bottom: 10px;
            }
            .AnswerSheet_time{
                margin-top: 20px;
                font-size: 24px;
                font-weight: bold;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                }
            }
            
        }
        .Nooperation_mock{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            opacity: 0.3;
            z-index: 99;
            text-align: center;
        }
        .Nooperation_img{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-75%);
            width: 360px;
            height: 280px;
            z-index: 100;
        }
    }
    .exammodal{
        text-align: center;
        p{
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin: 30px 0;
        }
        .box{
            font-size: 12px;
            color: #666666;
        }
        .exammodal_btn{
            display: flex;
            justify-content: space-around;
            padding: 16px;
            .exammodal_btn_item{ 
                width: 108px;
                font-size: 14px;
            }
        }
    }
</style>
