<template>
    <div class="TestPaper" @click="ClickAll">
        <Head />
        <div class="middle">
            <div class="content">
                <div class="SubjectList">
                    <div class="List_item" :class="'quesshow' + index" v-for="(item,index) in PapersData.questionList" :key="item.questionIndex">
                        <Paper :SubjectList="item" :TestDate="TestDate" :questionUseTime="questionUseTime" @ClearQuestionTime="ClearQuestionTime" @RefreshAnswer="RefreshAnswer"/>
                    </div>

                    <div class="last">已经是最后一题啦，做完就点击左侧交卷吧</div>
                </div>
            </div>
        </div>
        <div class="AnswerSheet" :class="{glide: !this.isAnswerSheet}">
            <div class="AnswerSheet_content">
                <div class="cardList" ref="scrollview" @mousewheel="scrollChange">
                    <div class="cardList_item" v-for="(item,index) in PapersData.cardList" :key="item.moduleId">
                        <div class="cardList_item_moduleName">{{item.moduleName}}</div>
                        <div class="questionList">
                            <div class="questionList_item" v-for="(item2,index2) in item.questionList" :class="{option_item_checked:item2.hadAnswer == true}" @click="ClickQuestionIndex(item2.questionIndex-1)">
                                {{item2.questionIndex}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pack_up" @click="ClickAnswerSheet">答题卡
                    <Icon type="ios-arrow-down"  v-show="this.isAnswerSheet"/>
                    <Icon type="ios-arrow-up" v-show="!this.isAnswerSheet"/>
                </div>
            </div>
        </div>
        <!-- 右下角模块 -->
        <div class="submit_but">
            <div class="submit_top">{{PapersData.name}}</div>
            <div class="AnswerSheet_time">
                <img src="@/assets/clock.png" alt="">
                <span>{{date}}</span>
            </div>
            <Button type="primary" @click="ClickSubmit" style="margin-top: 10px;width: 108px;">交卷</Button>
        </div>
        <!-- 无操作弹窗 -->
        <div class="Nooperation_pop" v-if="isNooperation" @click="operationMock">
            <div class="Nooperation_mock"></div>
            <img src="@/assets/Nooperation.png" alt="" class="Nooperation_img">
        </div>
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
import Paper from "@/components/Paper"
import { GetExerciseDetail, ExercisePaperAnswer} from "@/api/QuestionPapers"
export default {
    name: 'TestPaper',
    props: {
        PapersData: Object,
        courseExerciseId: String
    },
    components: {
        Head,
        Paper
    },
    data () {
       return {
            isAnswerSheet: true,
            showIndex: '',
            // 整卷累计总耗时
            date: "",
            timer: '',
            // 单题计时
            questionTimer: '',
            // 无操作计时
            NooperationTimer: '',
            // 整卷累计总耗时-秒
            TestDate: 0,
            // 本题累计总耗时-秒
            questionUseTime: 0,
            // 无操作计时 - 秒
            Nooperation: 0,
            isglide: false,
            isNooperation: false
       }
    },
    created () {
        
    },
    mounted () {
        // 计时
        this.TestDate = this.PapersData.useTime -1
        let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
        // 全卷计时
        this.timer = setInterval(() => {
            _this.TestDate = _this.TestDate+1
            _this.date =this.formatSeconds(_this.TestDate); // 修改数据date
        }, 1000)
        // 单题计时
        this.questionTimer = setInterval(() => {
            _this.questionUseTime = _this.questionUseTime+1
        }, 1000)
        // 无操作计时
        this.NooperationTimer = setInterval(() => {
            _this.Nooperation = _this.Nooperation+1
            // 两分钟无操作打开遮罩，暂停计时
            if(_this.Nooperation == 120){
                // 禁止滚动
                document.body.style.overflow = "hidden"
                this.isNooperation = true
                clearInterval(this.questionTimer);
                clearInterval(this.NooperationTimer);
            }
        }, 1000)
        // 监听页面滚动事件
        window.addEventListener("scroll", this.handleScroll);
        // 答题卡模块滚动事件
        const scrollview = this.$refs['scrollview'];
        scrollview.addEventListener('scroll', this.scrollChange, true);
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
        if(this.questionTimer){
            clearInterval(this.questionTimer); // 在Vue实例销毁前，清除我们的定时器
        }
        if(this.NooperationTimer){
            clearInterval(this.NooperationTimer); // 在Vue实例销毁前，清除我们的定时器
        }
        window.removeEventListener('scroll', this.handleScroll);//移除监听页面滚动事件
        // 移除答题卡模块滚动事件
        const scrollview = this.$refs['scrollview'];
        scrollview.removeEventListener('scroll', this.scrollChange, true);
    },
    methods: {
        // 展开、收起答题卡
        ClickAnswerSheet(){
            this.isAnswerSheet = !this.isAnswerSheet
        },

        // 点击答题卡跳到对应的位置
        ClickQuestionIndex(num){
            var el = document.getElementsByClassName(`quesshow${num}`)[0]
	      if (this.showIndex === num) {
	        this.showIndex = null
	      } else {
	        this.showIndex = num
	      }
	      this.$nextTick(function () {
	        window.scrollTo({'behavior': 'smooth', 'top': el.offsetTop-80})
	      })
        },

        // 点击交卷
        ClickSubmit(){
            this.$Modal.confirm({
                title: "检查好了吗？确认交卷吗？",
                content: "",
                okText: "确认交卷",
                cancelText: "我再看看",
                onOk: () => {
                    let param = {
                        userPaperId : this.PapersData.userPaperId,
                        useTime : this.TestDate,
                        // port 1小程序 2PC
                        port: 2,
                    }
                    ExercisePaperAnswer(param).then((res=>{
                        if(res.ret == 1){
                            if(this.PapersData.execType == 1){
                                // 练习试卷
                                this.$emit("handExercise");
                            }else if(this.PapersData.execType == 5){
                                // 测试试卷
                                this.$router.replace({path: '/QuestionPapers',query: {paperId: this.$route.query.paperId,belongId: this.$route.query.belongId,belongType: this.$route.query.belongType,state: 10,userPaperId: this.$route.query.userPaperId}})
                                this.$router.go(0);
                            }
                        }else{
                            console.log(res.msg)
                        }
                    }))
                }
            });
        },
        // 秒转时分秒
        formatSeconds(value) {
            let result = parseInt(value)
            let h = Math.floor(result / 3600) < 10 ? '0' +  Math.floor(result / 3600) : Math.floor(result / 3600);
            let m = Math.floor((result / 60 % 60)) < 10 ?  '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
            let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
            let res = '';
            res += `${h}`;
            res += `:${m}`;
            res += `:${s}`;
            return res;
        },

        // 清除本题做题时间
        ClearQuestionTime(){
            this.questionUseTime = 0
        },

        // 刷新答题卡
        RefreshAnswer(){
            let ExerciseData = {
                paperId: this.$route.query.paperId,
                belongId: this.$route.query.belongId,
                belongType: this.$route.query.belongType
            }
            GetExerciseDetail(ExerciseData).then((res)=>{
                this.PapersData.cardList = res.data.cardList
            })
        },

        // 全局点击事件
        ClickAll(){
            // 有操作清除无操作时间
            this.Nooperation = 0
        },

        // 监听页面滚动事件
        handleScroll(){
            // 有操作清除无操作时间
            this.Nooperation = 0
        },

        // 答题卡模块滚动事件
        scrollChange (e) {
            // 有操作清除无操作时间
            this.Nooperation = 0
        },

        // 点击未操作遮罩
        operationMock(){
            let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
            this.isNooperation = false
            // 恢复滚动
            document.body.style.overflow = "visible"
            // 单题计时
            this.questionTimer = setInterval(() => {
                _this.questionUseTime = _this.questionUseTime+1
            }, 1000)
            // 无操作计时
            this.NooperationTimer = setInterval(() => {
                _this.Nooperation = _this.Nooperation+1
                // 两分钟无操作打开遮罩，暂停计时
                if(_this.Nooperation == 120){
                    // 禁止滚动
                    document.body.style.overflow = "hidden"
                    this.isNooperation = true
                    clearInterval(this.questionTimer);
                    clearInterval(this.NooperationTimer);
                }
            }, 1000)
        }
    },
}
</script> 

<style lang='less' scoped>
    .TestPaper{
        min-height: 100vh;
        background-color: #f8f8f8;
        position: relative;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1030px;
            .content{
                width: 1030px;
                min-height: 100vh;
                text-align: left;
                background: #FFFFFF;
                border-radius: 12px;
                margin-top: 80px;
                padding: 43px 30px 77px;
                .SubjectList{
                    .last{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
        .AnswerSheet{
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1030px;
            max-height: 280px;
            background: #FFFFFF;
            box-shadow: 0px -12px 20px 0px #EEEEEE;
            border: 1px solid #D6D6D6;
            border-bottom: 0;
            z-index: 99;
            transition: all 0.2s ease-in-out;
            .AnswerSheet_content{
                width: 100%;
                height: 100%;
                position: relative;
                .cardList{
                    max-height: 280px;
                    text-align: left;
                    padding: 30px;
                    overflow: hidden;
                    overflow-y: auto;
                    .cardList_item{
                        padding-top: 10px;
                    }
                    .cardList_item:first-child{
                        padding-top: 0;
                    }
                    .cardList_item_moduleName{
                        font-size: 18px;
                        font-weight: bold;
                        color: #000000;
                        margin-bottom: 12px;
                    }
                    .questionList{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .questionList_item{
                            width: 36px;
                            height: 36px;
                            background: #FFFFFF;
                            border: 1px solid #D6D6D6;
                            border-radius: 8px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #666666;
                            text-align: center;
                            line-height: 36px;
                            margin-bottom: 10px;
                            margin-right: 10px;
                            cursor: pointer;
                        }
                        .questionList_item:hover{
                            background: #FB7878;
                            color: #FFFFFF;
                            border: none;
                        }
                        .questionList_item:active{
                            background: #FF9090;
                            color: #FFFFFF;
                            border: none;
                        }
                        .option_item_checked{
                            background-color: #FFEBEB;
                            color: #E30000;
                            border: 1px solid #FB7878;
                        }
                        .questionList_item:nth-child(21n){
                            margin-right: 0px;
                        }
                    }
                }
                .cardList::-webkit-scrollbar {
                  width: 5px;
                  height: 15px;
                }
                .cardList::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  background-color: #c1c1c1;
                }
                .cardList::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  background: #ededed;
                  border-radius: 10px;
                }
                .pack_up{
                    position: absolute;
                    top: -32px;
                    left: -1px;
                    width: 101px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    background: #FFFFFF;
                    border: 1px solid #D6D6D6;
                    box-shadow: 0px -12px 20px 0px #EEEEEE;
                    border-radius: 8px 8px 0px 0px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    cursor: pointer;
                }
                .pack_up:hover{
                    color: #FB7878;
                }
            }
        }
        .glide{
            top: calc(100vh - 3%);
        }
        .submit_but{
            position: fixed;
            width: 238px;
            background: #FFFFFF;
            box-shadow: 0px 0px 5px 0px #EEEEEE;
            border-radius: 8px;
            bottom: 20px;
            left: 50%;
            transform: translateX(221%);
            padding: 15px 10px 20px;
            .submit_top{
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                border-bottom: 1px solid #DBDBDB;
                padding-bottom: 10px;
            }
            .AnswerSheet_time{
                margin-top: 20px;
                font-size: 24px;
                font-weight: bold;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                }
            }
            
        }
        .Nooperation_mock{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            opacity: 0.3;
            z-index: 99;
            text-align: center;
        }
        .Nooperation_img{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-75%);
            width: 360px;
            height: 280px;
            z-index: 100;
        }
    }
</style>
