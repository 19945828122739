<template>
    <div class="Paper">
        <div class="materials" v-if="SubjectList.datumText != ''">
            <div class="materials_top">
                <span>材料</span>
                <Button @click="openMaterials(SubjectList.datumText)">材料浮窗</Button>
            </div>
            <div class="materials_details" v-html="SubjectList.datumText" @click="imageEnlargement"></div>
        </div>
        <div class="stem">
            <span>{{SubjectList.questionIndex}}、</span>
            <Tag color="green" v-if="SubjectList.typeName == '判断题'">{{SubjectList.moduleName}}-{{SubjectList.typeName}}</Tag>
            <Tag color="magenta" v-if="SubjectList.typeName == '单选题'">{{SubjectList.moduleName}}-{{SubjectList.typeName}}</Tag>
            <Tag color="blue" v-if="SubjectList.typeName == '多选题'">{{SubjectList.moduleName}}-{{SubjectList.typeName}}</Tag>
            <span v-html="SubjectList.stem" class="question_stem" @click="imageEnlargement"></span>
        </div>
        <!-- 多选题 -->
        <div class="optionList" v-if="SubjectList.typeName == '多选题'">
            <!-- 未提交 -->
            <div class="option_item" v-for="(item,index) in SubjectList.optionList" :key="item.optId" @click="ClickMultipleoption(item,index)">
                <div class="option_item_code" :class="{option_item_checked:item.isShow == true}">{{item.code}}</div>
                <p>{{item.con}}</p>
            </div>
        </div>
        <!-- 单选以及判断题 -->
        <div class="optionList" v-else>
            <div class="option_item" v-for="(item,index) in SubjectList.optionList" :key="item.optId" @click="Clickoption(item,index)">
                <div class="option_item_code" :class="{option_item_checked:optionindex == index}">{{item.code}}</div>
                <p>{{item.con}}</p>
            </div>
        </div>
        <div class="isCollect">
            <!-- <Button type="primary" v-if="SubjectList.typeName == '多选题'" @click="SubmitAnswer">提交答案</Button> -->
            <img src="@/assets/isCollect_true.png" alt="" @click="ClickCollect()" v-if="SubjectList.isCollect">
            <img src="@/assets/isCollect_false.png" alt="" @click="ClickCollect()" v-if="!SubjectList.isCollect">
            <Tooltip content="题目有误" placement="top">
                <img src="@/assets/mistake_false.png" alt="" class="mistake" v-if="!ismistake" @click="ClickMistake">
                <img src="@/assets/mistake_true.png" alt="" class="mistake" v-if="ismistake">
            </Tooltip>
        </div>
        <!-- <div class="isCollect" v-if="!SubjectList.isCollect"><Button type="primary" v-if="SubjectList.typeName == '多选题'" @click="SubmitAnswer">提交答案</Button><img src="@/assets/isCollect_false.png" alt="" @click="ClickCollect()"></div> -->
        <div class="item_line"></div>
        <!-- 放大图片弹窗 -->
        <Modal v-model="Imgmodal" fullscreen footer-hide :closable="false">
            <div class="BIGimg" @click="Imgmodal = false"><img :src="BIGimg" alt=""></div>
        </Modal>
        
        <!-- 材料浮窗 -->
        <Modal v-model="MaterialsModal" draggable scrollable footer-hide :closable="false" width='485' title="材料">
            <div class="MaterialsModal" v-html="materialsData" @click="imageEnlargement"></div>
            <div class="Materialsclosable"><Button @click="MaterialsModal = false">关闭弹窗</Button></div>
        </Modal>
    </div>
</template>

<script>
import { CollectQuestionPost } from "@/api/Review"
import { QuestionFeedback, QuestionAnswer} from "@/api/QuestionPapers"
export default {
    name: 'demo',
    props: {
        SubjectList: Object,
        // 整卷累计总耗时-秒
        TestDate: Number,
        // 本题累计总耗时-秒
        questionUseTime: Number
    },
    components: {},
    data () {
       return {
            // 放大图片
            Imgmodal: false,
            BIGimg: '',
            // 材料浮窗
            MaterialsModal: false,
            materialsData: '',
            optionindex: 999999,
            // 多选题选中的答案数组
            ArroptId:[],
            // 是否点击错误
            ismistake: false,
            // 写入本题第一次耗时
            WriteTime: ""
        }
    },
    created () {
    },
    mounted () {
        this.checkChoice()
    },
    methods: {
        // 进来检查改题是否有作答
        checkChoice(){
            this.SubjectList.optionList.map((item,index)=>{
                if(item.userSel){
                    if(this.SubjectList.typeName == '多选题'){
                        this.$set(item,'isShow',true)
                        this.ArroptId.push(item.optId)
                    }else{
                        this.optionindex = index
                    }
                }
            })
            // console.log(this.SubjectList)
        },

        // 放大图片
        imageEnlargement(e){
            if (e.target.nodeName == 'IMG') {//判断点击富文本内容为img图片
                this.BIGimg = e.target.currentSrc
                this.Imgmodal = true
   	        } else {
   	        	console.log("点击内容不为img")
   	        }
        },

        // 打开材料浮窗
        openMaterials(e){
            this.materialsData = e
            this.MaterialsModal = true
        },

        // 取消收藏
        ClickCollect(){
            let CollectData = {
                questionId: this.SubjectList.questionId,
                userQuestionId: this.SubjectList.userQuestionId
            }
            CollectQuestionPost(CollectData).then((res) =>{
                if(res.ret == 1){
                    this.SubjectList.isCollect = !this.SubjectList.isCollect
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 点击多选选项
        ClickMultipleoption(item,index){
            if(!item.isShow) {   // 判断是否有isShow属性
				this.$set(item,'isShow',false)  // 没有则为当前对象设置该属性为false
				item.isShow = !item.isShow
			}else {
				item.isShow = !item.isShow
			}
            if (this.ArroptId.indexOf(item.optId) === -1) {
                this.ArroptId.push(item.optId)
            } else {
                this.ArroptId.splice(this.ArroptId.indexOf(item.optId), 1)
            }
            // 排序
            this.ArroptId.sort()
            this.SubmitAnswer()
        },

        // 多选题提交答案
        SubmitAnswer(){
            // if(this.ArroptId.length == 0){
            //     this.$Message.warning('该题目还未选择答案喔！');
            //     return
            // }
            // 写入第一次作答时间
            if(this.WriteTime == ''){
                this.WriteTime = this.questionUseTime
            }
            let AnswerData = {
                userQuestionId : this.SubjectList.userQuestionId,
                questionUseTime: this.WriteTime,
                paperUseTime: this.TestDate,
                answer: this.ArroptId.join(",")
            }
            // 做了题刷新时间
            this.$emit("ClearQuestionTime");
            QuestionAnswer(AnswerData).then((res=>{
                if(res.ret == 1){
                    // 刷新答题卡
                    this.$emit("RefreshAnswer");
                }else{
                    console.log(res.msg)
                }
            }))
        },

        // 点击选项
        Clickoption(data,index){
            this.optionindex = index
            this.code = data.code
            // 写入第一次作答时间
            if(this.WriteTime == ''){
                this.WriteTime = this.questionUseTime
            }
            let AnswerData = {
                userQuestionId : this.SubjectList.userQuestionId,
                questionUseTime: this.WriteTime,
                paperUseTime: this.TestDate,
                answer: data.optId
            }
            // 做了题刷新时间
            this.$emit("ClearQuestionTime");
            QuestionAnswer(AnswerData).then((res=>{
                if(res.ret == 1){
                    // 刷新答题卡
                    this.$emit("RefreshAnswer");
                }else{
                    console.log(res.msg)
                }
            }))
        },

        // 错题反馈
        ClickMistake(){
            QuestionFeedback({questionId:this.SubjectList.questionId}).then((res)=>{
                if(res.ret == 1){
                    this.ismistake = true
                }
            })
        }
    },
}
</script> 

<style lang='less' scoped>
    .Paper{
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        .materials{
            background: #F5F5F5;
            border: 1px solid #D6D6D6;
            border-radius: 16px 8px 8px 8px;
            margin-bottom: 30px;
            .materials_top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 24px 30px 28px;
                font-size: 20px;
                font-weight: bold;
                color: #333333;
            }
            .materials_details{
                padding: 20px;
                color: #666666;
                background: #FFFFFF;
                border-top: 1px solid #D6D6D6;
                border-radius: 16px 8px 8px 8px;
                /deep/img{
                    display: block;
                    margin: 10px auto 0;
                    max-width: 1100px;
                }
            }
        }
        .stem{
            .question_stem{
                /deep/img{
                    display: block;
                    margin: 10px auto 0;
                    max-width: 1100px;
                }
            }
        }
        .optionList{
            margin-top: 30px;
            padding: 0 20px;
            .option_item{
                display: flex;
                align-items: center;
                padding: 10px 0;
                cursor: pointer;
                .option_item_code{
                    width: 32px;
                    height: 32px;
                    border: 1px solid #AAAAAA;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 32px;
                    margin: 0 10px;
                }
                .option_item_correct{
                    background-color: #2FC732;
                    color: #FFFFFF;
                    border: 1px solid #2FC732;
                }
                .option_item_error{
                    background-color: #FB7878;
                    color: #FFFFFF;
                    border: 1px solid #FB7878;
                }
                .option_item_checked{
                    background-color: #F29C05;
                    color: #FFFFFF;
                    border: 1px solid #F29C05;
                }
            }
            .option_item:hover{
                background: #F5F5F5;
                border-radius: 4px;
            }
            .option_item:last-child{
                margin-bottom: 0;
            }
        }
        .isCollect{
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img{
                cursor: pointer;
                margin-left: 30px;
            }
            .mistake{
                margin-top: 6px;
                margin-left: 16px;
            }
        }
        .item_line{
            border: 1px solid #F5F5F5;
            margin: 20px 0 30px;
        }
    }
    // 放大图片弹窗
    .BIGimg{
        width: 100%;
        height: 100%;
        text-align: center;
        img{
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-width: 1900px;
            height: auto;
        }
    }
    // 材料弹窗
    .MaterialsModal{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        padding: 4px;
        margin-bottom: 37px;
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
        /deep/img{
            display: block;
            margin: 10px auto 0;
        }
    }
    .MaterialsModal::-webkit-scrollbar {
      width: 5px;
      height: 15px;
    }
    .MaterialsModal::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c1c1c1;
    }
    .MaterialsModal::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #ededed;
      border-radius: 10px;
    }
    .Materialsclosable{
        text-align: center;
    }
</style>
