<template>
    <div class="AnswerParse">
        <Head />
        <div class="middle">
            <div class="content">
                <div class="CollectList">
                    <div class="List_item" :class="'quesshow' + index" v-for="(item,index) in PapersData.questionList" :key="item.questionIndex">
                        <div class="materials" v-if="item.datumText != ''">
                            <div class="materials_top">
                                <span>材料</span>
                                <Button @click="openMaterials(item.datumText)">材料浮窗</Button>
                            </div>
                            <div class="materials_details" v-html="item.datumText" @click="imageEnlargement"></div>
                        </div>
                        <div class="stem">
                            <span>{{item.questionIndex}}、</span>
                            <Tag color="green" v-if="item.typeName == '判断题'">{{item.moduleName}}-{{item.typeName}}</Tag>
                            <Tag color="magenta" v-if="item.typeName == '单选题'">{{item.moduleName}}-{{item.typeName}}</Tag>
                            <Tag color="blue" v-if="item.typeName == '多选题'">{{item.moduleName}}-{{item.typeName}}</Tag>
                            <span v-html="item.stem" class="question_stem" @click="imageEnlargement"></span>
                        </div>
                        <div class="optionList">
                            <div class="option_item" v-for="(item2,index2) in item.optionList" :key="item2.optId">
                                <div class="option_item_code" :class="{option_item_correct:item2.userSel === true}" v-if="item2.isAnswer">{{item2.code}}</div>
                                <div class="option_item_code" :class="{option_item_error:item2.userSel === true}" v-if="!item2.isAnswer">{{item2.code}}</div>
                                <p>{{item2.con}}</p>
                            </div>
                        </div>
                        <div class="isCollect">
                            <img src="@/assets/isCollect_true.png" alt="" @click="ClickCollect(item,index)" v-if="item.isCollect">
                            <img src="@/assets/isCollect_false.png" alt="" @click="ClickCollect(item,index)" v-if="!item.isCollect">
                            <Tooltip content="题目有误" placement="top">
                                <img src="@/assets/mistake_false.png" alt="" class="mistake" v-if="!item.ismistake" @click="ClickMistake(item,index)">
                                <img src="@/assets/mistake_true.png" alt="" class="mistake" v-if="item.ismistake">
                            </Tooltip>
                        </div>
                        <div class="analysis">
                            <p>【答案解析】 正确答案：{{item.rightAnswerStr}}  <span style="margin-left: 20px;">您选的答案：{{item.userAnswerStr}}</span></p>
                            <div v-html="item.analysis" class="analysis_details" @click="imageEnlargement"></div>
                            <p>【考点模块】 {{item.knowledgeDesc}}</p>
                            <p>该知识点的全站正确率：{{item.knowledgeRightRate}}%</p>
                        </div>
                        <div class="item_line"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 答题卡 -->
        <div class="AnswerSheet" :class="{glide: !this.isAnswerSheet}">
            <div class="AnswerSheet_content">
                <div class="cardList">
                    <div class="cardList_item" v-for="(item,index) in PapersData.cardList" :key="item.moduleId">
                        <div class="cardList_item_moduleName">{{item.moduleName}} <span class="cardList_item_rightRate">模块正确率：<span>{{item.rightRate}}%</span></span> </div>
                        <div class="questionList">
                            <div class="questionList_item" v-for="(item2,index2) in item.questionList"
                            :key="item.questionIndex" 
                            :class="{option_item_error:item2.hadAnswer == true&&item2.resultState != 10,option_item_correct:item2.resultState == 10}"
                            @click="ClickQuestionIndex(item2.questionIndex-1)">
                                {{item2.questionIndex}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pack_up" @click="isAnswerSheet=!isAnswerSheet">答题卡
                    <Icon type="ios-arrow-down"  v-show="this.isAnswerSheet"/>
                    <Icon type="ios-arrow-up" v-show="!this.isAnswerSheet"/>
                </div>
            </div>
        </div>
        <!-- 右下角模块 -->
        <div class="submit_but">
            <div class="submit_top">{{PapersData.name}}</div>
            <!-- 测试试卷 -->
            <div class="AnswerSheet_time" v-if="PapersData.execType == 5">
                <div class="AnswerSheet_time_item">
                    <div class="AnswerSheet_time_left_top">本卷得分</div>
                    <div class="AnswerSheet_time_left_bottom">
                        <span class="score">{{PapersData.getScore}}</span>
                        <span>/</span>
                        <span>{{PapersData.score}}</span>
                    </div>
                </div>
                <div class="AnswerSheet_time_line"></div>
                <div class="AnswerSheet_time_item">
                    <div class="AnswerSheet_time_left_top">做题耗时</div>
                    <div class="AnswerSheet_time_left_bottom">
                        <span class="time_consuming">{{useTime}}</span>
                    </div>
                </div>
            </div>
            <!-- 练习试卷 -->
            <div class="AnswerSheet_time" v-if="PapersData.execType == 1">
                <div class="AnswerSheet_time_item">
                    <div class="AnswerSheet_time_left_top">本次答题正确率</div>
                    <div class="AnswerSheet_time_left_bottom">
                        <span class="score">{{PapersData.thisRightRate}}%</span>
                    </div>
                </div>
                <div class="AnswerSheet_time_line"></div>
                <div class="AnswerSheet_time_item">
                    <div class="AnswerSheet_time_left_top">全卷答题正确率</div>
                    <div class="AnswerSheet_time_left_bottom">
                        <span class="time_consuming">{{PapersData.allRightRate}}%</span>
                    </div>
                </div>
            </div>
            <!-- 测试试卷 -->
            <Button type="primary" @click="gohome"  v-if="PapersData.execType == 5 || PapersData.execType == 1" style="margin-top: 40px;width: 100%;">返回首页</Button>
            <!-- 练习试卷 -->
            <!-- <div class="exercise_btn" v-if="PapersData.execType == 1" >
                <Button type="primary" @click="again" class="again">再做一套</Button>
                <Button  @click="gohome" class="gohome">返回首页</Button>
            </div> -->
        </div>

        <!-- 放大图片弹窗 -->
        <Modal v-model="Imgmodal" fullscreen footer-hide :closable="false">
            <div class="BIGimg" @click="Imgmodal = false"><img :src="BIGimg" alt=""></div>
        </Modal>
        
        <!-- 材料浮窗 -->
        <Modal v-model="MaterialsModal" draggable scrollable footer-hide :closable="false" width='485' title="材料">
            <div class="MaterialsModal" v-html="materialsData" @click="imageEnlargement"></div>
            <div class="Materialsclosable"><Button @click="MaterialsModal = false">关闭弹窗</Button></div>
        </Modal>

        <!-- 我要吐槽弹窗 -->
        <Dissmodal ref="Click_Diss" :title="Diss_title"/>
        <!-- 我要吐槽 -->
        <img src="@/assets/testDiss.png" alt="" class="testDiss" @click="ClickDiss">
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
// 我要吐槽弹窗
import Dissmodal from "@/components/Dissmodal"
import { CollectQuestionPost} from "@/api/Review"
import { QuestionFeedback, GetPaperResult} from "@/api/QuestionPapers"
export default {
    name: 'AnswerParse',
    props: {
        userPaperId: String
    },
    components: {
        Head,
        Dissmodal,
    },
    data () {
       return {
            PapersData: {},
            Diss_title: "题目反馈",
            // 放大图片
            Imgmodal: false,
            BIGimg: '',
            // 材料浮窗
            MaterialsModal: false,
            materialsData: '',
            // 收起、隐藏答题卡
            isAnswerSheet: true,
            // 作答用时
            useTime: "",
            isglide: false
       }
    },
    created () {
        this.GetPaperResult()
    },
    mounted () {
    },
    methods: {
        // 获取作答结果
        GetPaperResult(){
            GetPaperResult(this.userPaperId).then((res)=>{
                this.PapersData = res.data
                this.useTime = this.formatSeconds(res.data.useTime)
            })
        },

        // 放大图片
        imageEnlargement(e){
            if (e.target.nodeName == 'IMG') {//判断点击富文本内容为img图片
                this.BIGimg = e.target.currentSrc
                this.Imgmodal = true
   	        } else {
   	        	console.log("点击内容不为img")
   	        }
        },

        // 打开材料浮窗
        openMaterials(e){
            this.materialsData = e
            this.MaterialsModal = true
        },

        // 收藏、取消收藏
        ClickCollect(data,index){
            let CollectData = {
                questionId: data.questionId,
                userQuestionId: data.userQuestionId
            }
            CollectQuestionPost(CollectData).then((res) =>{
                if(res.ret == 1){
                    this.PapersData.questionList[index].isCollect = !this.PapersData.questionList[index].isCollect
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 错题反馈
        ClickMistake(data,index){
            QuestionFeedback({questionId:data.questionId}).then((res)=>{
                if(res.ret == 1){
                    this.$set(data,'ismistake',true)
                }
            })
        },


        // 点击我要吐槽
        ClickDiss(){
            this.$refs.Click_Diss.ClickDissmodal()
        },

        // 返回首页
        gohome(){
            this.$router.push({path: '/'})
        },

        // 点击答题卡跳到对应的位置
        ClickQuestionIndex(num){
            var el = document.getElementsByClassName(`quesshow${num}`)[0]
	      if (this.showIndex === num) {
	        this.showIndex = null
	      } else {
	        this.showIndex = num
	      }
	      this.$nextTick(function () {
	        window.scrollTo({'behavior': 'smooth', 'top': el.offsetTop-80})
	      })
        },

        // 秒转分秒
        formatSeconds(s) {
            //计算分钟
			//算法：将秒数除以60，然后下舍入，既得到分钟数
			var h;
			h  =   Math.floor(s/60);
			//计算秒
			//算法：取得秒%60的余数，既得到秒数
			s  =   s%60;
			//将变量转换为字符串
			h    +=    '';
			s    +=    '';
			//如果只有一位数，前面增加一个0
			h  =   (h.length==1)?'0'+h:h;
			s  =   (s.length==1)?'0'+s:s;
			return h+':'+s;
        },

        // 再做一套
        again(){
            this.$router.go(0);
        }
    },
}
</script> 

<style lang='less' scoped>
    .AnswerParse{
        min-height: 100vh;
        background-color: #f8f8f8;
        position: relative;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1030px;
            .content{
                width: 1030px;
                min-height: 100vh;
                text-align: left;
                background: #FFFFFF;
                border-radius: 12px;
                margin-top: 80px;
                padding: 43px 30px 77px;
                .CollectList{
                    .List_item{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        .materials{
                            background: #F5F5F5;
                            border: 1px solid #D6D6D6;
                            border-radius: 16px 8px 8px 8px;
                            margin-bottom: 30px;
                            .materials_top{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 24px 30px 28px;
                                font-size: 20px;
                                font-weight: bold;
                                color: #333333;
                            }
                            .materials_details{
                                padding: 20px;
                                color: #666666;
                                background: #FFFFFF;
                                border-top: 1px solid #D6D6D6;
                                border-radius: 16px 8px 8px 8px;
                                /deep/img{
                                    display: block;
                                    margin: 10px auto 0;
                                    max-width: 1100px;
                                }
                            }
                        }
                        .stem{
                            .question_stem{
                                /deep/img{
                                    display: block;
                                    margin: 10px auto 0;
                                    max-width: 1100px;
                                }
                            }
                        }
                        .optionList{
                            margin-top: 30px;
                            padding: 0 20px;
                            .option_item{
                                display: flex;
                                align-items: center;
                                padding: 10px 0;
                                .option_item_code{
                                    width: 32px;
                                    height: 32px;
                                    border: 1px solid #AAAAAA;
                                    border-radius: 50%;
                                    text-align: center;
                                    line-height: 32px;
                                    margin: 0 10px;
                                }
                                .option_item_correct{
                                    background-color: #2FC732;
                                    color: #FFFFFF;
                                    border: 1px solid #2FC732;
                                }
                                .option_item_error{
                                    background-color: #FB7878;
                                    color: #FFFFFF;
                                    border: 1px solid #FB7878;
                                }
                            }
                            .option_item:hover{
                                background: #F5F5F5;
                                border-radius: 4px;
                            }
                            .option_item:last-child{
                                margin-bottom: 0;
                            }
                        }
                        .isCollect{
                            text-align: right;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            img{
                                cursor: pointer;
                                margin-left: 30px;
                            }
                            .mistake{
                                margin-top: 6px;
                                margin-left: 16px;
                            }
                        }
                        .analysis{
                            background: #F5F5F5;
                            border-radius: 8px;
                            padding: 20px;
                            font-size: 14px;
                            .analysis_details{
                                margin-bottom: 30px;
                                /deep/img{
                                    display: block;
                                    margin: 10px auto 0;
                                    max-width: 1100px;
                                }
                            }
                        }
                        .item_line{
                            border: 1px solid #F5F5F5;
                            margin: 20px 0 30px;
                        }
                    }
                }
            }
        }
    }
    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
    // 放大图片弹窗
    .BIGimg{
        width: 100%;
        height: 100%;
        text-align: center;
        img{
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-width: 1900px;
            height: auto;
        }
    }
    // 材料弹窗
    .MaterialsModal{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        padding: 4px;
        margin-bottom: 37px;
        max-height: 800px;
        overflow: hidden;
        overflow-y: auto;
        /deep/img{
            display: block;
            margin: 10px auto 0;
        }
    }
    .MaterialsModal::-webkit-scrollbar {
      width: 5px;
      height: 15px;
    }
    .MaterialsModal::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c1c1c1;
    }
    .MaterialsModal::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #ededed;
      border-radius: 10px;
    }
    .Materialsclosable{
        text-align: center;
    }
    .AnswerSheet{
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1030px;
        max-height: 280px;
        background: #FFFFFF;
        box-shadow: 0px -12px 20px 0px #EEEEEE;
        border: 1px solid #D6D6D6;
        border-bottom: 0;
        z-index: 99;
        transition: all 0.2s ease-in-out;
        .AnswerSheet_content{
            width: 100%;
            height: 100%;
            position: relative;
            .cardList{
                max-height: 280px;
                text-align: left;
                padding: 30px;
                overflow: hidden;
                overflow-y: auto;
                .cardList_item{
                    padding-top: 10px;
                }
                .cardList_item:first-child{
                    padding-top: 0;
                }
                .cardList_item_moduleName{
                    font-size: 18px;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 12px;
                    .cardList_item_rightRate{
                        margin-left: 15px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #999999;
                        span{
                            color: #D13F3F;
                        }
                    }
                }
                .questionList{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .questionList_item{
                        width: 36px;
                        height: 36px;
                        background: #FFFFFF;
                        border: 1px solid #D6D6D6;
                        border-radius: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #666666;
                        text-align: center;
                        line-height: 36px;
                        margin-bottom: 10px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    .option_item_correct{
                        background-color: #2FC732;
                        color: #FFFFFF;
                        border: 1px solid #2FC732;
                    }
                    .option_item_error{
                        background-color: #FB7878;
                        color: #FFFFFF;
                        border: 1px solid #FB7878;
                    }
                    .questionList_item:nth-child(21n){
                        margin-right: 0px;
                    }
                }
            }
            .cardList::-webkit-scrollbar {
              width: 5px;
              height: 15px;
            }
            .cardList::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #c1c1c1;
            }
            .cardList::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: #ededed;
              border-radius: 10px;
            }
            .pack_up{
                position: absolute;
                top: -32px;
                left: -1px;
                width: 101px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: #FFFFFF;
                border: 1px solid #D6D6D6;
                box-shadow: 0px -12px 20px 0px #EEEEEE;
                border-radius: 8px 8px 0px 0px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                cursor: pointer;
            }
            .pack_up:hover{
                color: #FB7878;
            }
        }
    }
    .glide{
        top: calc(100vh - 3%);
    }

    .submit_but{
        position: fixed;
        width: 238px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 0px #EEEEEE;
        border-radius: 8px;
        bottom: 20px;
        left: 50%;
        transform: translateX(221%);
        padding: 15px 10px 20px;
        .submit_top{
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            border-bottom: 1px solid #DBDBDB;
            padding-bottom: 10px;
        }
        .AnswerSheet_time{
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .AnswerSheet_time_item{
                .AnswerSheet_time_left_top{
                    font-size: 12px;
                    font-weight: 400;
                    color: #666;
                }
                .AnswerSheet_time_left_bottom{
                    margin-top: 12px;
                    font-size: 22px;
                    font-weight: bold;
                    color: #000000;
                    .score{
                        color: #17AE1A;
                    }
                }
            }
            .AnswerSheet_time_line{
                width: 1px;
                height: 41px;
                background: #F2F2F2;
                margin: 0 20px;
            }
        }
        .exercise_btn{
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            .again{
                width: 108px;
            }
            .gohome{
                width: 108px;
            }
        }
    }
    
    .testDiss{
        position: fixed;
        bottom: 281px;
        right: 50%;
        transform: translateX(595%);
    }
</style>
